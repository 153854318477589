

import React, { useState, useEffect, FC } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, useParams } from "react-router-dom"
import { HubConnectionBuilder, HubConnectionState, HubConnection, HttpTransportType } from '@microsoft/signalr';
import Epay from './Epay'

interface RouteParams {
  slug: string
}

function Payment() {
  const { sessionUid } = useParams<Record<string, string | undefined>>()
  const [type, setType] = useState("Ingen type");
  
     // axios.post(`${process.env.PAYMENT_API}/payment/companyCard`, {
//

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_PAYMENT_API}/payment/` + sessionUid) //validerer sessionet
      .then(res => {
        console.log(res)
        setType(res.data.type.name)
      })

    setUpSignalRConnection("test").then((con) => {
      console.log(con);
      //connection = con;
    });
  }, []);

  const getUsers = async () => {
    // const users = await getBoardUsers(boardId);
    // setUsers(users);
  };

  const setUpSignalRConnection = async (boardId: string) => {
    const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_PAYMENT_API}/signalRHub`, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    })
      .withAutomaticReconnect()
      .build();

    connection.on('Message', (message: string) => {
      console.log('Message', message);
    });
    connection.on('EpaySuccess', x => {
      console.log("epay succes!");
    });
    

    try {
      await connection.start();
    } catch (err) {
      console.log(err);
    }

    if (connection.state === HubConnectionState.Connected) {
      connection.invoke('SubscribeToEpay', sessionUid).catch((err: Error) => {
        return console.error(err.toString());
      });
    }

    return connection;
  };

  return (
    <>
      <Epay />
    </>
  );
};

export default Payment;