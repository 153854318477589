import React, { useState } from "react";
import { Button, Container, Grid, makeStyles, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter as Router, Route, useParams } from "react-router-dom"
import axios from 'axios';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    text: {
        color: '#707070',
        whiteSpace: 'normal',
    },
    container: {
        textAlign: 'center'
    },

    textfield: {
        width: "70%",
        color: '#78b51a',
        "& label.Mui-focused": {
            color: "#707070"
        },
        "& .MuiInput-underline": {
            borderBottomColor: "#707070"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#78b51a"
        },
        "& .MuiInput-underline:focused": {
            borderBottomColor: "#7d7070"
        }
    },
    saveBtn: {
        '&:focus': {
            outline: '0px',
        },
        backgroundColor: '#78b51a',
        margin: '0.7%',
        color: '#ffffff',

    }

}));


export function ApcoaCoins() {
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [errorText, setErrorText] = useState("");
    const [error, setError] = useState(false);
    const history = useHistory();
    const { sessionUid } = useParams<Record<string, string | undefined>>()
    function AddCoins(sessionUid: string | undefined) {
        //`${process.env.PAYMENT_API}/signalRHub`
        console.log(sessionUid)
        axios.post(`${process.env.REACT_APP_PAYMENT_API}/payment/coins`, {
            sessionUid: sessionUid,
            code: code
        })
            .then(res => {
                console.log(res);
                history.push({
                    pathname: '/coins/success',
                });
            }).catch(res => {
                console.log(res)
                history.push({
                    pathname: '/coins/decline',
                });
            })
    
    
    }
    function ValidateCode() {
        if (code === "") {
            setErrorText("Udfyld venligst dette felt");
            setError(true);
        } else {


            //validateCoins/{code}
            // Use this when axios call returns OK-status
           
                AddCoins(sessionUid);
         

            // Use this when code is invalid
            setErrorText("Koden er desværre forkert eller ugyldig");
            setError(true);

            //Use this when sessionid doesn't exist to redirect to decline-page
            /* history.push({
                pathname: '/coins/decline',
            }); */


        }
    }


    return (
        <Container className={classes.container}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <br />
                    <Typography className={classes.text} variant="body2">Indtast koden i feltet nedenfor:</Typography>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textfield} autoFocus={true} onChange={(e) => setCode(e.target.value)} value={code} label="Kode" error={error} helperText={errorText} ></TextField>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="large" className={classes.saveBtn} onClick={() => { ValidateCode() }}>Indløs koden</Button>
                </Grid>

            </Grid>
        </Container>
    );
}