

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IPaymentWindow } from '../../models/EpayPaymentWindow';
import { IPaymentWindowOptions } from '../../models/EpayPaymentWindow';
import { BrowserRouter as Router, Route, useParams } from "react-router-dom"
declare const PaymentWindow: any;


function Epay() {
  const loadEpay = (callback : any) => {
    const existingScript = document.getElementById('epayWindow');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/paymentwindow.js';
      script.id = 'epayWindow';
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };
  
  const { sessionUid } = useParams<Record<string, string | undefined>>()
  console.log(sessionUid);

  const [type, setType] = useState("Ingen type");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // addCreditCard();
    loadEpay(() => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (loaded) {
     
      var acceptParam = "";
if( document.referrer.includes("udlejning") ){
  acceptParam = document.referrer;
}
        //
      axios.get<IPaymentWindowOptions>(`${process.env.REACT_APP_PAYMENT_API}/payment/${sessionUid}/getparams`) 
      .then(res => {
        console.log(res)
        const paymentWindow = new PaymentWindow(res.data);
        paymentWindow.options = res.data;
        paymentWindow.open();
      })
    }
  }, [loaded])

  // function addCreditCard() {

  //   const options = {
  //     countryCode: "DK",
  //     orderId: `CC${('0000000' + sessionId).slice(-7)}`,
  //     amount: 0,
  //     subscription: true,
  //     instantCallback: true,
  //     callbackParams: {
  //       'type': 5,
  //       'sessionId': sessionId
  //     },
  //     instantCapture: false
  //   };

  //   const opt: any = {
  //       merchantnumber: "",
  //       currency: 'DKK',
  //       amount: Math.floor(options.amount * 100),
  //       orderid: options.orderId,
  //       mobile: 1,
  //       accepturl: "",
  //       subscription: options.subscription === true ? 1 : 0,
  //       instantcapture: options.instantCapture === true ? 1 : 0,
  //       instantcallback: options.instantCallback === true ? 1 : 0,
  //       windowstate: 3,
  //       subscriptiontype: 'cardonfile',
  //       sessionUID: "test",
  //       // iframeheight: 420,
  //       // iframewidth: '100%',
  //       // cssurl: environment.epayCss
  //     };

  //     // let callbackUrl = environment.epayCallbackUrl;

  //     // if (options.callbackParams) {
  //     //   callbackUrl += '?';

  //     //   // tslint:disable-next-line:forin
  //     //   for (const key in options.callbackParams) {
  //     //     const value = options.callbackParams[key];
  //     //     callbackUrl += `${key}=${value}&`;
  //     //   }
  //     // }

  //     // opt.callbackurl = callbackUrl;

   
  //     opt.hash = "";

  //     const paymentwindow = {} as IPaymentWindow;
  //     paymentwindow.options = opt;
  //     paymentwindow.open();
  // }
  
  return (
    <div className="container">
      {/* her skal der være en switchcase med de forskellige typer */}
      {type} 
    </div>
  );
};

export default Epay;