import React, { useState, useEffect, FC } from 'react';

import { Button, Container, Grid, makeStyles, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter as Router, Route, useParams } from "react-router-dom"
import { HubConnectionBuilder, HubConnectionState, HubConnection, HttpTransportType } from '@microsoft/signalr';

import axios from 'axios';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#707070',
        fontWeight: 'bold',
        whiteSpace: 'normal',
        marginTop: '10%'
    },
    text: {
        color: '#707070',
        whiteSpace: 'normal',
    },
    container: {
        textAlign: 'center'
    },

    textfield: {
        width: "70%",
        color: '#78b51a',
        "& label.Mui-focused": {
            color: "#707070"
        },
        "& .MuiInput-underline": {
            borderBottomColor: "#707070"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#78b51a"
        },
        "& .MuiInput-underline:focused": {
            borderBottomColor: "#7d7070"
        }
    },
    cancelBtn: {
        '&:focus': {
            outline: '0px',
        },
        backgroundColor: '#707070',
        color: '#ffffff',
        margin: '0.7%',
        border: '0px solid',
    },
    saveBtn: {
        '&:focus': {
            outline: '0px',
        },
        backgroundColor: '#78b51a',
        margin: '0.7%',
        color: '#ffffff',

    }

}));
//okay

export default function CompanyParking() {
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [errorText, setErrorText] = useState("");
    const [error, setError] = useState(false);
    const history = useHistory();
    const { sessionUid } = useParams<Record<string, string | undefined>>()

    useEffect(() => {
        //test

        //`${process.env.PAYMENT_API}/signalRHub`
        axios.get(`${process.env.REACT_APP_PAYMENT_API}/payment/` + sessionUid) //validerer sessionet
            .then(res => {

            }).catch(res => {
                console.log(res)
                history.push({
                    pathname: '/companyparking/decline',
                });
            })

    }, []);
    //Validates whether code is valid.




    function ValidateCode() {
        if (code === "") {
            setErrorText("Udfyld venligst dette felt");
            setError(true);
        } else {
        //`${process.env.PAYMENT_API}/signalRHub`
            //
            axios.get(`${process.env.REACT_APP_PAYMENT_API}/payment/validateCard/` + code) //validates code
                .then(res => {
                    setErrorText("");
                    setError(false);
                    AddCompanyCard(sessionUid);
                }).catch(res => {
                    setErrorText("Koden er desværre forkert eller ugyldig");
                    setError(true);
                })
        }
    }

    //Adds company paymentmethod to user
    function AddCompanyCard(sessionUid: string | undefined) {
        //`${process.env.PAYMENT_API}/signalRHub`
        axios.post(`${process.env.REACT_APP_PAYMENT_API}/payment/companyCard`, {
            sessionUid: sessionUid,
            code: code
        })
            .then(res => {
                console.log(res);
                history.push({
                    pathname: '/companyparking/success',
                });
            }).catch(res => {
                console.log(res)
                history.push({
                    pathname: '/companyparking/decline',
                });
            })


    }

    return (
        <Container className={classes.container}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="body1">Erhvervsparkering</Typography>
                    <br />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.text} variant="body2">Indtast koden i feltet nedenfor:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textfield} autoFocus={true} onChange={(e) => setCode(e.target.value)} value={code} label="Kode" error={error} helperText={errorText}></TextField>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" className={classes.cancelBtn} onClick={() => {
                        history.push({
                            pathname: '/companyparking/decline',
                        });
                    }}>Annullér</Button>
                    <Button variant="contained" className={classes.saveBtn} onClick={() => { ValidateCode() }}>Gem</Button>
                </Grid>

            </Grid>
        </Container>

    )
}