import React, { useState } from "react";
import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { BrowserRouter as Router, Route, useParams } from "react-router-dom"
import axios from 'axios';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { BorderAllRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#707070',
        fontWeight: 'bold',
        whiteSpace: 'normal',
        marginTop: '10%'
    },
    text: {
        color: '#707070',
        whiteSpace: 'normal',
    },
    dialog: {
        height: '300 px',
        width: 'auto',
    },
    dialogcontent: {
        textAlign: 'center',
        height: '300 px',
        width: '200 px',
    },
    doneicon: {
        color: '#78b51a',
        scale: '1.8',
        fontSize: '8.0rem',
    },



}));

export function CompanySuccess() {
    const classes = useStyles();

    return (
        <Dialog open maxWidth="lg" className={classes.dialog}>
            <DialogContent className={classes.dialogcontent}>

                <DoneIcon fontSize="large" className={classes.doneicon} />
                <Typography>Dit erhvervskort er tilføjet</Typography>
            </DialogContent>
        </Dialog>

    )
}