import React, { useState, useEffect } from 'react';
import { HubConnectionBuilder, HubConnectionState, HttpTransportType } from '@microsoft/signalr';

function App(){
  useEffect(() => {
    setUpSignalRConnection("test").then((con) => {
      console.log(con);
    });
  }, []);

  const setUpSignalRConnection = async (boardId: string) => {
    const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_PAYMENT_API}/signalRHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    connection.on('Message', (message: string) => {
      console.log('Message', message);
    });
    connection.on('EpaySuccess', x => {
      console.log("epay succes!");
    });

    try {
      await connection.start();
    } catch (err) {
      console.log(err);
    }

    if (connection.state === HubConnectionState.Connected) {
      connection.invoke('SubscribeToEpay', "test").catch((err: Error) => {
        return console.error(err.toString());
      });
    }

    return connection;
  };
  
  return (
    <div className="container">
     hej
    </div>
  );
};

export default App;