import React, { useState, useEffect, FC } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Payment from './main/payment/Payment';
import { EpaySuccess } from './main/epay/Success';
import { EpayDecline } from './main/epay/Decline';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import CompanyParking from './main/companyparking/CompanyParking';
import { CompanySuccess } from './main/companyparking/Success';
import { CompanyDecline } from './main/companyparking/Decline';
import {ApcoaCoins} from './main/coins/ApcoaCoins';
import { CoinsSuccess } from './main/coins/Success';
import { CoinsDecline } from './main/coins/Decline';

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={App} />
      <Route path="/success/payment" component={EpaySuccess} />
       <Route path="/payment/decline" component={EpayDecline} />
       <Route path="/payment/:sessionUid" component={Payment} />    
       <Route path="/companyparking/:sessionUid" component={CompanyParking} />
       <Route path="/companyparking/success" component={CompanySuccess} />
       <Route path="/companyparking/decline" component={CompanyDecline} />
      {/* <Route path="/contact" component={Contact} /> */}
      <Route path="/coins/:sessionUid" component={ApcoaCoins} />
      <Route path="/coins/success" component={CoinsSuccess} />
      <Route path="/coins/decline" component={CoinsDecline} />

    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
